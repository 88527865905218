import React, { useEffect, useState } from "react";
import ArticlePreview from "../components/ArticlePreview";
import RecentArticles from "../components/RecentArticles";
import { Article } from "../types/apiTypes";
import Loading from "../components/UI/Loading";
import { fetchLatestArticle } from "../myModules/api";

const Home: React.FC = () => {
  const [article, setArticle] =
  useState<Article | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const latestArticle = await fetchLatestArticle()
        setArticle(latestArticle);
      } catch (error) {
        console.error("Error fetching latest article:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="text-black ">
      <h1 className="text-4xl font-semibold tracking-wide">Latest Article</h1>
      {article !== null ? (
        <ArticlePreview articlePreview={article} />
      ) : (
        <div className="flex-c min-h-[300px]"><Loading /></div>
      )}
      <RecentArticles />
    </div>
  );
};

export default Home;
