import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Article } from "../types/apiTypes";
import parse from "html-react-parser";
import Loading from "./UI/Loading";
import { fetchArticlePage } from "../myModules/api";

const RecentArticles: React.FC = () => {
  const [articlePreviews, setArticlePreviews] = useState<(Article|null)[]>([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const numArticles = 10;
        const page = 0;
        const articles = await fetchArticlePage(page, numArticles)
        setArticlePreviews(articles.slice(1));//remove the first article because it's already in the main preview
      } catch (error) {
        console.error("Error fetching article previews:", error);
      }
    };

    fetchData();
  }, []);

  if (articlePreviews.length === 0) {
    return (
      <div className="flex-c">
        <Loading />
      </div>
    );
  }
  return (
    <section className="flex flex-col gap-6">
      <h1 className="text-4xl font-semibold tracking-wide text-center">
        Recent Articles
      </h1>
      <div className="gap-4 flex flex-col">
        {articlePreviews.map((item, index) => {
          if (item == null) {
            return 
          }
          return (
            <div
              key={index}
              className="flex flex-col sm:flex-row bg-primary/10 rounded-lg sm:rounded-2xl shadow-md overflow-hidden">
              <div className="flex-shrink-0 ">
                {/* @ts-ignore */}
                <img
                  src={item.articleImageURL.toString()}
                  alt={item.articleTitle}
                  className="sm:max-w-[200px] max-sm:max-h-[300px] max-sm:w-full object-cover h-full"
                />
              </div>
              <div className="flex flex-col justify-between gap-4 p-4 flex-grow">
                <div className="flex flex-col gap-2">
                  <Link to={`/articles/${item.articleId}`}>
                    <h2 className="text-2xl lora text-primary hover:text-secondary">
                      {item.articleTitle}
                    </h2>{" "}
                  </Link>
                  <span className="text-xs font-medium">
                    {" "}
                    {item.articleDate}
                  </span>
                  <div className="article-preview">
                    {parse(item.articleText)}
                  </div>
                </div>
                <div className="flex sm:justify-end">
                  <Link
                    to={`/articles/${item.articleId}`}
                    className="btn btn-gr">
                    Read more
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default RecentArticles;
